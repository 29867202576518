.container {
  position: relative;
  max-width: 500px; /* Ensure the container takes full width */
  height: 48px;
}

.select {
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  appearance: none; /* Remove default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
  box-sizing: border-box;
}

.select:disabled {
  background: var(--gray-100);
  color: var(--gray-400);
  cursor: not-allowed;
}
.icon {
  position: absolute;
  right: 20px; /* Adjust right position to align with padding */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Make sure the icon doesn't block select interaction */
}
